import {
  yogaImage1,
  yogaImage2,
  yogaImage3,
  yogaImage4,
  yogaImage5,
  yogaImage6,
  yogaImage7,
  yogaImage8,
  yogaImage9,
  yogaImage10,
} from '../images/caseYoga';

import {
  taskyImage1,
  taskyImage2,
  taskyImage3,
  taskyImage4,
  taskyImage5,
  taskyImage6,
  taskyImage7,
  taskyImage8,
  taskyImage9,
  taskyImage10,
  taskyImage11,
  taskyImage12,
  taskyImage13,
  taskyImage14,
  taskyImage15,
  taskyImage16,
  taskyImage17,
  taskyImage18,
  taskyImage19,
  taskyImage20,
  taskyImage21,
  taskyImage22,
} from '../images/caseTasky';

import {
  bbqImage1,
  bbqImage2,
  bbqImage3,
  bbqImage4,
  bbqImage5,
  bbqImage6,
  bbqImage7,
  bbqImage8,
  bbqImage9,
  bbqImage10,
  bbqImage11,
  bbqImage12,
} from '../images/caseBbqBox';

import {
  bettingImage1,
  bettingImage2,
  bettingImage3,
  bettingImage4,
  bettingImage5,
  bettingImage6,
  bettingImage7,
  bettingImage8,
  bettingImage9,
  bettingImage10,
  bettingImage11,
  bettingImage12,
  bettingImage13,
  bettingImage14,
  bettingImage15,
  bettingImage16,
  bettingImage17,
} from '../images/caseBetting';

import {
  kramImage1,
  kramImage2,
  kramImage3,
  kramImage4,
  kramImage5,
  kramImage6,
  kramImage7,
  kramImage8,
  kramImage9,
  kramImage10,
  kramImage11,
  kramImage12,
  kramImage13,
  kramImage14,
  kramImage15,
  kramImage16,
  kramImage17,
  kramImage18,
  kramImage19,
} from '../images/caseKram';

import {
  stephenImage1,
  stephenImage2,
  stephenImage3,
  stephenImage4,
  stephenImage5,
  stephenImage6,
  stephenImage7,
  stephenImage8,
} from '../images/caseStephen';

export const STEPHEN_IMAGES = [
  { src: stephenImage1 },
  { src: stephenImage2 },
  { src: stephenImage3 },
  { src: stephenImage4 },
  { src: stephenImage5 },
  { src: stephenImage6 },
  { src: stephenImage7 },
  { src: stephenImage8 },
];

export const YOGA_IMAGES = [
  { src: yogaImage1 },
  { src: yogaImage2 },
  { src: yogaImage3 },
  { src: yogaImage4 },
  { src: yogaImage5 },
  { src: yogaImage6 },
  { src: yogaImage7 },
  { src: yogaImage8 },
  { src: yogaImage9 },
  { src: yogaImage10 },
];

export const TASKY_IMAGES = [
  { src: taskyImage1 },
  { src: taskyImage2 },
  { src: taskyImage3 },
  { src: taskyImage4 },
  { src: taskyImage5 },
  { src: taskyImage6 },
  { src: taskyImage7 },
  { src: taskyImage8 },
  { src: taskyImage9 },
  { src: taskyImage10 },
  { src: taskyImage11 },
  { src: taskyImage12 },
  { src: taskyImage13 },
  { src: taskyImage14 },
  { src: taskyImage15 },
  { src: taskyImage16 },
  { src: taskyImage17 },
  { src: taskyImage18 },
  { src: taskyImage19 },
  { src: taskyImage20 },
  { src: taskyImage21 },
  { src: taskyImage22 },
];

export const KRAM_IMAGES = [
  { src: kramImage1 },
  { src: kramImage2 },
  { src: kramImage3 },
  { src: kramImage4 },
  { src: kramImage5 },
  { src: kramImage6 },
  { src: kramImage7 },
  { src: kramImage8 },
  { src: kramImage9 },
  { src: kramImage10 },
  { src: kramImage11 },
  { src: kramImage12 },
  { src: kramImage13 },
  { src: kramImage14 },
  { src: kramImage15 },
  { src: kramImage16 },
  { src: kramImage17 },
  { src: kramImage18 },
  { src: kramImage19 },
];

export const BETTING_IMAGES = [
  { src: bettingImage1 },
  { src: bettingImage2 },
  { src: bettingImage3 },
  { src: bettingImage4 },
  { src: bettingImage5 },
  { src: bettingImage6 },
  { src: bettingImage7 },
  { src: bettingImage8 },
  { src: bettingImage9 },
  { src: bettingImage10 },
  { src: bettingImage11 },
  { src: bettingImage12 },
  { src: bettingImage13 },
  { src: bettingImage14 },
  { src: bettingImage15 },
  { src: bettingImage16 },
  { src: bettingImage17 },
];

export const BBQ_IMAGES = [
  { src: bbqImage1 },
  { src: bbqImage2 },
  { src: bbqImage3 },
  { src: bbqImage4 },
  { src: bbqImage5 },
  { src: bbqImage6 },
  { src: bbqImage7 },
  { src: bbqImage8 },
  { src: bbqImage9 },
  { src: bbqImage10 },
  { src: bbqImage11 },
  { src: bbqImage12 },
];

export const IMAGES = {
  portfolio: STEPHEN_IMAGES,
  'play-retreat': YOGA_IMAGES,
  'bbq-box': BBQ_IMAGES,
  wizcric: BETTING_IMAGES,
  kram: KRAM_IMAGES,
  polestar: TASKY_IMAGES,
};
